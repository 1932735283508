import {
  calculateAverageContactLinkedClick,
  calculateAverageInfoLinkedClick,
  calculateConversationWithIntentPercentage,
  calculateCPA,
  calculateCPC,
  calculateCPI,
  calculateCPM,
  calculateCTC,
  calculateCTI,
  calculateCTR,
  calculateRelativeIntentStrength,
} from "@/utils/reporting.util";
import {
  GridColDef,
  GridColumnVisibilityModel,
} from "@mui/x-data-grid-premium";

export type ColumnsDefinition = GridColDef & { visible: boolean };

export const reportingColumns = [
  {
    field: "LandingPage",
    headerName: "Landing Page",
    pinnable: true,
    visible: true,
    width: 100,
  },
  {
    field: "AdSetName",
    headerName: "Ad Set Name",
    width: 100,
  },
  {
    field: "AdName",
    headerName: "Ad Name",
    width: 100,
  },
  {
    field: "AdSetID",
    headerName: "Ad Set ID",
    width: 100,
    visible: false,
  },
  {
    field: "AdSetStatus",
    visible: false,
    headerName: "Ad Set Status",
    width: 80,
  },

  {
    field: "AdSetFreeText",
    visible: true,
    headerName: "Ad Set Comments",
    width: 110,
  },
  {
    field: "AdSetCountry",
    visible: true,
    headerName: "Country",
    width: 70,
  },

  {
    field: "AdSetTags",
    visible: true,
    headerName: "Ad Set Tags",
    width: 90,
  },
  {
    field: "SKU",
    visible: true,
    headerName: "SKU",
    width: 60,
  },

  {
    field: "AdID",
    headerName: "Ad ID",
    width: 100,
    visible: false,
  },
  {
    field: "AdStatus",
    headerName: "Ad Status",
    width: 80,
    visible: false,
  },

  {
    field: "AdFreeText",
    headerName: "Ad Comments",
    width: 120,
    visible: true,
  },
  {
    field: "AdTags",
    headerName: "Ad Tags",
    width: 120,
    visible: true,
  },
  {
    field: "Date",
    headerName: "Date",
    width: 90,
  },

  {
    field: "AgentFriendlyName",
    headerName: "Agent Name",
    width: 100,
  },

  {
    field: "CampaignName",
    headerName: "Campaign Name",
    width: 100,
  },
  {
    field: "CampaignID",
    headerName: "Campaign ID",
    width: 100,
    visible: false,
  },

  {
    field: "CampaignStatus",
    headerName: "Campaign Status",
    width: 80,
    visible: false,
  },
  {
    field: "CampaignFreeText",
    headerName: "Campaign Comments",
    width: 100,
    visible: true,
  },

  {
    field: "CampaignTags",
    headerName: "Campaign Tags",
    width: 100,
    visible: true,
  },

  {
    field: "Spend",
    headerName: "Ad Spend",
    type: "number",
    width: 100,
    valueFormatter: (value: number) => (value ? value.toFixed(2) : value),
  },

  {
    field: "Impressions",
    headerName: "Impressions",
    type: "number",
    width: 100,
    valueFormatter: (value: number) => (value ? value.toFixed(2) : value),
  },
  {
    field: "OutboundClicks",
    headerName: "Clicks",
    type: "number",
    width: 100,
    valueFormatter: (value: number) => (value ? value.toFixed(2) : value),
  },

  {
    field: "CTR",
    headerName: "CTR",
    type: "number",
    width: 100,
    valueGetter: (_, row) => {
      if (!row.Impressions || !row.OutboundClicks) {
        return null;
      }
      return calculateCTR(row.OutboundClicks, row.Impressions);
    },
    valueFormatter: (value: number) => (value ? value.toFixed(2) : 0),
  },

  {
    field: "CPC",
    headerName: "CPC",
    type: "number",
    width: 100,
    valueGetter: (_, row) => {
      if (!row.Spend || !row.OutboundClicks) {
        return null;
      }
      return calculateCPC(row.Spend, row.OutboundClicks);
    },
    valueFormatter: (value: number) => (value ? value.toFixed(2) : 0),
  },

  {
    field: "CPM",
    headerName: "CPM",
    type: "number",
    width: 100,
    valueGetter: (_, row) => {
      if (!row.Spend || !row.Impressions) {
        return null;
      }
      return calculateCPM(row.Spend, row.Impressions);
    },
    valueFormatter: (value: number) => (value ? value.toFixed(2) : 0),
  },

  {
    field: "Pageviews",
    headerName: "Pageviews",
    type: "number",
    width: 100,
    valueFormatter: (value: number) => (value ? value.toFixed(2) : value),
  },
  {
    field: "Sessions",
    headerName: "Engaged Sessions",
    type: "number",
    width: 100,
    valueFormatter: (value: number) => (value ? value.toFixed(2) : value),
  },

  {
    field: "SessionMinutes",
    headerName: "Session Minutes",
    type: "number",
    width: 100,
    valueFormatter: (value: number) => (value ? value.toFixed(2) : value),
  },
  {
    field: "ConversationsStarted",
    headerName: "Convers. Started",
    type: "number",
    width: 100,
    valueFormatter: (value: number) => (value ? value.toFixed(2) : value),
  },
  {
    field: "ConversationsWithIntendStarted",
    headerName: "Convers. with Intent",
    type: "number",
    width: 100,
    valueFormatter: (value: number) => (value ? value.toFixed(2) : value),
  },

  {
    field: "ConversationsWithIntendPercentage",
    headerName: "Convers. with Intent %",
    type: "number",
    width: 100,
    valueGetter: (_, row) => {
      return calculateConversationWithIntentPercentage(
        row.ConversationsWithIntendStarted,
        row.ConversationsStarted
      );
    },
    valueFormatter: (value: number) => (value ? value.toFixed(2) : value),
  },

  {
    field: "IntendStrength",
    headerName: "Sum Intent Strength",
    type: "number",
    width: 100,
    valueFormatter: (value: number) => (value ? value.toFixed(2) : value),
  },

  {
    field: "RelIntendStrenght",
    headerName: "Rel. Intent Strength",
    type: "number",
    width: 100,
    valueGetter: (_, row) => {
      return calculateRelativeIntentStrength(
        row.IntendStrength,
        row.ConversationsWithIntendStarted
      );
    },
    valueFormatter: (value: number) => (value ? value.toFixed(2) : value),
  },

  {
    field: "TotalTurns",
    headerName: "Total Turns",
    type: "number",
    width: 100,
    valueFormatter: (value: number) => (value ? value.toFixed(2) : value),
  },

  {
    field: "AverageTurns",
    headerName: "Avg. Turns",
    type: "number",
    width: 100,
    valueGetter: (_, row) => {
      if (!row.TotalTurns || !row.ConversationsStarted) {
        return null;
      }
      return row.TotalTurns / row.ConversationsStarted;
    },
    valueFormatter: (value: number) => (value ? value.toFixed(2) : 0),
  },

  {
    field: "CTC",
    headerName: "CTC",
    type: "number",
    width: 100,
    valueGetter: (_, row) => {
      if (!row.ConversationsStarted || !row.OutboundClicks) {
        return null;
      }
      return calculateCTC(row.ConversationsStarted, row.OutboundClicks);
    },
    valueFormatter: (value: number) => (value ? value.toFixed(2) : 0),
  },
  {
    field: "CPA",
    headerName: "CPA",
    type: "number",
    width: 100,
    valueGetter: (_, row) => {
      if (!row.Spend || !row.ConversationsStarted) {
        return null;
      }
      return calculateCPA(row.Spend, row.ConversationsStarted);
    },
    valueFormatter: (value: number) => (value ? value.toFixed(2) : 0),
  },
  {
    field: "CTI",
    headerName: "CTI",
    type: "number",
    width: 100,
    valueGetter: (_, row) => {
      if (!row.ConversationsWithIntendStarted || !row.OutboundClicks) {
        return null;
      }
      return calculateCTI(
        row.ConversationsWithIntendStarted,
        row.OutboundClicks
      );
    },
    valueFormatter: (value: number) => (value ? value.toFixed(2) : 0),
  },
  {
    field: "CPI",
    headerName: "CPI",
    type: "number",
    width: 100,
    valueGetter: (_, row) => {
      if (!row.Spend || !row.ConversationsWithIntendStarted) {
        return null;
      }
      return calculateCPI(row.Spend, row.ConversationsWithIntendStarted);
    },
    valueFormatter: (value: number) => (value ? value.toFixed(2) : 0),
  },

  {
    field: "InfoLinksClicked",
    headerName: "Total Info Links Clicked",
    type: "number",
    width: 100,
    valueFormatter: (value: number) => (value ? value.toFixed(2) : value),
  },

  {
    field: "ContactLinksClicked",
    headerName: "Total Contact Links Clicked",
    type: "number",
    width: 100,
    valueFormatter: (value: number) => (value ? value.toFixed(2) : value),
  },

  {
    field: "AvgInfoLinksClicked",
    headerName: "Avg. Info Links Clicked",
    type: "number",
    width: 100,
    valueGetter: (_, row) => {
      return calculateAverageInfoLinkedClick(
        row.InfoLinksClicked,
        row.ConversationsStarted
      );
    },
    valueFormatter: (value: number) => (value ? value.toFixed(2) : value),
  },

  {
    field: "AvgContactLinksClicked",
    headerName: "Avg. Contact Links Clicked",
    type: "number",
    width: 100,
    valueGetter: (_, row) => {
      return calculateAverageContactLinkedClick(
        row.ContactLinksClicked,
        row.ConversationsStarted
      );
    },
    valueFormatter: (value: number) => (value ? value.toFixed(2) : value),
  },

  {
    field: "TestNr",
    headerName: "Test Nr.",
    type: "number",
    width: 100,
  },

  {
    field: "TestName",
    headerName: "Test Name",
    width: 100,
  },

  {
    field: "TestObjective",
    headerName: "Test Objective",
    width: 100,
  },

  {
    field: "TestCreatedAt",
    headerName: "Test Created At",
    width: 100,
  },

  {
    field: "TestCreatedBy",
    headerName: "Test Created By",
    width: 100,
  },

  {
    field: "TestDescription",
    headerName: "Test Description",
    width: 100,
  },

  {
    field: "TestResults",
    headerName: "Test Results",
    width: 100,
  },

  {
    field: "TestChangeLog",
    headerName: "Test Change Log",
    width: 100,
  },

  {
    field: "TestUpdatedAt",
    headerName: "Test Updated At",
    width: 100,
  },
] as ColumnsDefinition[];

export const reportingColumnsVisibility = reportingColumns.reduce((acc, column) => {
  acc[column.field] = column.visible;

  return acc;
}, {} as GridColumnVisibilityModel);
