import { ChatBotTemplate } from "@/components/templates/ChatBotTemplate";
import { IConversationEvents } from "@/models/conversation";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useSearchParams } from "react-router-dom";

export default function InfoAgentDataBot() {
  const { data_model, sku, version } = useParams();

  const [searchParam] = useSearchParams();
  const navigate = useNavigate();

  const [events, setEvents] = useState<IConversationEvents>({
    utmCampaign: searchParam.get("utm_campaign") || undefined,
    utmMedium: searchParam.get("utm_medium") || undefined,
    utmSource: searchParam.get("utm_source") || undefined,
    utmTerm: searchParam.get("utm_term") || undefined,
    fbclid: searchParam.get("fbclid") || undefined,
  });

  const instant_chat_param = searchParam.get("instant_chat");
  const show_predefined_questask_responses_param = searchParam.get("predefined_tiles_responses");
  const conversation_id = searchParam.get("cvsid");
  const source = searchParam.get("source");


  useEffect(() => {
    if(source) {
      const url = window.atob(source)
      if(url) {
        navigate(url);
      }
    }
  }, [source, navigate])

  useEffect(() => {
    console.log("effect");
    const handleMessage = (event: MessageEvent<IConversationEvents>) => {
      console.log("getting event message =======> ", event.data);
      console.log("event.data ==> ", event.data);
      if (event?.data?.url && !events.url) {
        console.log("persisting event.data");
        setEvents({...events, ...event.data});
      }
    };

    window.addEventListener("message", handleMessage);
    return () => window.removeEventListener("message", handleMessage);
  }, [events]);

  if (!data_model) return <div>No Data Model Found</div>;
  return (
    <ChatBotTemplate
      data_model={data_model}
      info_agent_webview={true}
      conversation_id={conversation_id}
      sku={sku}
      version={version}
      events={{...events, landingPage: searchParam.get("parentURL") || window.location.href || undefined}}
      instant_chat={instant_chat_param ? instant_chat_param.toLowerCase() == "true" : false}
      show_predefined_questask_responses={show_predefined_questask_responses_param ? show_predefined_questask_responses_param.toLowerCase() == "true" : false}
    />
  );
}
