import { useAppSelector } from "@/store";
import { chatbotSalesAgentApi } from "@/store/api/chatbot";
import { useParams } from "react-router";
import { useSearchParams } from "react-router-dom";

const useFetchPredefinedQuestasks = () => {
  const selectedAccount = useAppSelector(
    (state) => state.store.selectedAccount
  );
  const { data_model, sku, version } = useParams();
    const [searchParams] = useSearchParams();
  
  const selectedAgentId = searchParams.get("selected_agent");

  console.log('questask ccalling ', data_model, sku);

  const store_name = selectedAccount || data_model;
  const { data, isLoading, error, isError, isFetching } =
  chatbotSalesAgentApi.usePreDefinedQuestionsQuery(
      { store_name: store_name || "", sku: sku || selectedAgentId, version },
      {
        skip: !(store_name),
      }
    );

console.log('data is ', data);

  return { data: data?.data, isLoading, error, isError, isFetching };
};

export default useFetchPredefinedQuestasks;
