import MainCard from "@/components/atoms/Card/MainCard";
import { DropdownMenu } from "@/components/molecules/DropdownMenu";
import { DropdownMenuShare } from "@/components/molecules/DropdownMenuShare";
import ChatbotCustomTemplate from "@/components/organisms/ChatbotCustomTemplate";
import {
  MessageLeft,
  MessageRight,
} from "@/components/organisms/ChatbotMessages";
import { ChatbotTextInput } from "@/components/organisms/ChatbotTextInput";
import useChatbot from "@/hooks/chatbot/useChatbot";
import useChatbotConversation from "@/hooks/chatbot/useChatbotConversation";
import useChatbotMessageFeedback from "@/hooks/chatbot/useChatbotMessageFeedback";
import useChatbotMessageReaction from "@/hooks/chatbot/useChatbotMessageReaction";
import useFetchDataModelAgents from "@/hooks/chatbot/useFetchDataModelAgents";
import useFetchAccountState from "@/hooks/store-management/useFetchAccountState";
import { DataModelAgentWithVersion } from "@/models/agent";
import { IConversationEvents } from "@/models/conversation";
import { IThreadMessage } from "@/models/threadMessage";
import { useAppDispatch, useAppSelector } from "@/store";
import { chatbotSalesAgentApi } from "@/store/api/chatbot";
import { setSessionId } from "@/store/slices/chatbot";
import { sendEventToParentContainer } from "@/utils/events.util";
import { ensureHttpsUrl } from "@/utils/helpers";

import {
  Box,
  Button,
  capitalize,
  CircularProgress,
  Grid,
  Grid2,
  Paper,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";

interface IChatBotTemplate {
  sessionId?: string;
  data_model?: string;
  sku?: string;
  version?: string;
  conversation_id?: string | null;
  events?: IConversationEvents;
  info_agent_webview?: boolean;
  instant_chat?: boolean;
  show_predefined_questask_responses?: boolean;
}

export function ChatBotTemplate({
  sessionId,
  data_model,
  sku,
  version,
  conversation_id,
  events,
  info_agent_webview = false,
  instant_chat,
  show_predefined_questask_responses,
}: IChatBotTemplate) {
  console.log("instant chat is .. ", instant_chat);

  const matches = useMediaQuery((theme) => theme.breakpoints.up("sm"));
  const matchesMd = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const [selectedDataModelAgent, setSelectedDataModelAgent] =
    useState<DataModelAgentWithVersion>();
  const [selectedVersion, setSelectedVersion] = useState<{
    Version: number;
    Status: string;
  }>();
  const { sessionId: chatbotSessionId } = useAppSelector(
    (state) => state.chatbot
  );

  const effectiveSessionId = info_agent_webview ? chatbotSessionId : sessionId;
  const [thread, setThread] = useState<IThreadMessage[]>([]);

  const shouldInitConversation = () => {
    if (!info_agent_webview) return false;
    if (instant_chat && thread.length === 1) return true;
    if (!instant_chat && thread.length === 0) return true;
    return false;
  };

  const { queryChatbot, isLoading } = useChatbot({
    session_id: effectiveSessionId,
    info_agent_webview,
    data_model: info_agent_webview ? data_model : null,
    sku: info_agent_webview ? sku : selectedDataModelAgent?.PromptSKU || null,
    version: info_agent_webview
      ? version
      : selectedVersion?.Version?.toString() || null,
    instant_chat_fisrt_conversation: instant_chat && thread.length === 0,
    show_predefined_questask_responses,
    events,
    init_conversation: shouldInitConversation(),
  });

  const messageEndRef = useRef<HTMLDivElement>(null);

  const { accountState } = useFetchAccountState({
    fetchOnlyStore: true,
  });

  const { data: dataModelDetail } =
    chatbotSalesAgentApi.useGetDatamodelDetailQuery(
      { store: data_model || accountState.id || "" },
      { skip: !data_model || !accountState }
    );

  const { queryChatbotMessageReaction } = useChatbotMessageReaction({});

  const { data: agentsByDataModel, isLoading: agentsByDataModelLoading } =
    useFetchDataModelAgents({ info_agent_webview });

  useEffect(() => {
    const selectedAgentId = searchParams.get("selected_agent");
    const selectedVersion = searchParams.get("selected_version");
    if (selectedAgentId && agentsByDataModel) {
      const agent = agentsByDataModel.find(
        (agent) => agent.PromptSKU === selectedAgentId
      );
      if (agent) {
        if (selectedVersion) {
          const version = agent.Versions.find(
            (version) => version.Version === parseInt(selectedVersion)
          );
          if (version) {
            setSelectedVersion(version);
          }
        }
        setSelectedDataModelAgent(agent);
      }
    } else if (
      !selectedDataModelAgent &&
      agentsByDataModel &&
      agentsByDataModel?.length > 0
    ) {
      setSelectedDataModelAgent(
        agentsByDataModel?.filter((e) => e.IsDefault)?.[0]
      );
      setSearchParams({
        ...searchParams,
        selected_agent: agentsByDataModel?.filter((e) => e.IsDefault)?.[0]
          ?.PromptSKU,
        selected_version: getDefaultVersionOfAgent(
          agentsByDataModel?.filter((e) => e.IsDefault)?.[0]
        ).toString(),
      });
    }
  }, [
    agentsByDataModel,
    selectedDataModelAgent,
    searchParams,
    setSearchParams,
  ]);

  const { fetchConversation, isLoading: conversationLoading } =
    useChatbotConversation({ info_agent_webview });

  const [sendConversationMediaLinkClick] =
    chatbotSalesAgentApi.useConversationMediaLinkClickMutation();

  const navigate = useNavigate();
  const location = useLocation();

  const { queryChatbotFeedback, isLoading: feedbackLoading } =
    useChatbotMessageFeedback({});

  useEffect(() => {
    if (info_agent_webview) {
      const paperElement = document.getElementById("main-chatbot-paper");
      if (paperElement) {
        paperElement.scrollTop = paperElement.scrollHeight;
      }
      if (thread.length === 0 && instant_chat) {
        onPromptSubmit("", true);
      }
    } else {
      messageEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info_agent_webview, thread, instant_chat]);

  const handleFetchConversation = useCallback(
    async ({ session_id }: { session_id: string }) => {
      const conversation = await fetchConversation({ session_id });
      if (conversation) {
        setThread(conversation);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleClickLink = (link: string, media_type: string) => {
    try {
      sendConversationMediaLinkClick({
        conversation_id: effectiveSessionId || "",
        media_type,
        link,
      });
    } catch (error) {
      console.error("Error sending conversation media link click:", error);
    }
  };

  useEffect(() => {
    if (sessionId) {
      console.log("Inside session id location state block");
      if (location.state?.thread) {
        setThread(location.state?.thread);
      } else {
        // Fetchin conversation data..
        handleFetchConversation({ session_id: sessionId });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleFetchConversation, location.state, sessionId]);

  useEffect(() => {
    if (conversation_id && !thread[0]) {
      console.log("Inside conversation id location state block");
      // Fetchin conversation data..
      dispatch(setSessionId(conversation_id));
      handleFetchConversation({ session_id: conversation_id });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleFetchConversation, conversation_id]);

  const getDefaultVersionOfAgent = (agentObj: DataModelAgentWithVersion) => {
    return (
      agentObj.Versions.find((e) => e.Status === "production")?.Version ||
      agentObj.Versions[0].Version
    );
  };

  const onPromptSubmit = async (
    prompt: string,
    isIntro: boolean = false,
    preDefinedQuestaskPosition?: number | null
  ) => {
    console.log("prompt is ", prompt);
    if (!isIntro) {
      setThread((thread) => [
        ...thread,
        { orientation: "right", message: prompt, request_id: "", sql: null },
      ]);
    }

    const result = await queryChatbot({
      prompt,
      isIntro,
      preDefinedQuestaskPosition,
    });
    if (result) {
      if (
        result?.session_id &&
        result?.session_id != sessionId &&
        !info_agent_webview
      ) {
        console.log("current thread is ", thread);
        const selectedAgentId = searchParams.get("selected_agent");
        navigate(
          `/databot/${
            result?.session_id
          }?selected_agent=${selectedAgentId}&account=${accountState.name.toLowerCase()}`,
          {
            state: {
              thread: [
                {
                  orientation: "right",
                  message: prompt,
                  request_id: "",
                  sql: null,
                },
                {
                  orientation: "left",
                  message: result?.data?.response,
                  request_id: result?.request_id,
                  sql: result?.data?.sql,
                  image: result?.data?.image,
                  chart: result?.data?.chart,
                  response_total_time: result?.data?.response_time?.total_time,
                },
              ],
            },
          }
        );
      } else {
        if (info_agent_webview && result?.session_id) {
          dispatch(setSessionId(result.session_id));
          searchParams.set("cvsid", result?.session_id);
          setSearchParams(searchParams);
          if (shouldInitConversation()) {
            // Sending event to parent..
            sendEventToParentContainer({ conversation_id: result?.session_id });
          } else {
            console.log("Initializing conversation : false");
          }
        }

        setThread((thread) => [
          ...thread,
          {
            orientation: "left",
            message: result?.data?.response,
            request_id: result?.request_id,
            sql: result?.data?.sql,
            image: result?.data?.image,
            chart: result?.data?.chart,
            response_total_time: result?.data?.response_time?.total_time,
          },
        ]);
      }
    }
  };

  const handleThumbsUp = (request_id?: string) => {
    console.log("thread is ", thread, request_id);
    if (!request_id) return;

    // Check if thread already has any reaction..
    const threadReaction = thread.find(
      (e) => e.request_id === request_id
    )?.like;
    if (threadReaction === true) return;

    // Remote and local update to the reaction..
    queryChatbotMessageReaction({ like: true, request_id });
    setThread((thread) =>
      thread.map((e) =>
        e.request_id === request_id ? { ...e, like: true } : e
      )
    );
  };
  const handleThumbsDown = (request_id?: string) => {
    if (!request_id) return;

    // Check if thread already has any reaction..
    const threadReaction = thread.find(
      (e) => e.request_id === request_id
    )?.like;
    if (threadReaction === false) return;

    // Remote and local update to the reaction..
    queryChatbotMessageReaction({ like: false, request_id });
    setThread((thread) =>
      thread.map((e) =>
        e.request_id === request_id ? { ...e, like: false } : e
      )
    );
  };

  const handleQueryChatbotFeedback = async ({
    request_id,
    feedback,
  }: {
    request_id?: string;
    feedback: string;
  }) => {
    if (!request_id) return;
    await queryChatbotFeedback({ comment: feedback, request_id });
    setThread((thread) =>
      thread.map((e) =>
        e.request_id === request_id ? { ...e, feedback: feedback } : e
      )
    );
  };

  const onCustomPromptSubmit = (prompt: string, position: number) => {
    onPromptSubmit(prompt, false, position);
  };

  const handleAgentSelection = (dataModelAgent: DataModelAgentWithVersion) => {
    setSelectedDataModelAgent(dataModelAgent);
    // searchParams.set("selected_agent", dataModelAgent.PromptSKU);
    // setSearchParams(searchParams);
    // setSearchParams({
    //   ...searchParams,
    //   selected_agent: dataModelAgent.PromptSKU,
    //   selected_version: getDefaultVersionOfAgent(dataModelAgent).toString(),
    // });
    navigate(
      `/databot?account=${accountState.name.toLowerCase()}&selected_agent=${
        dataModelAgent.PromptSKU
      }&selected_version=${getDefaultVersionOfAgent(dataModelAgent).toString()}`
    );
  };

  const handleVersionSelection = (version: {
    Version: number;
    Status: string;
  }) => {
    setSelectedVersion(version);
    // searchParams.set("selected_version", version.Version.toString());
    // setSearchParams(searchParams);
    if (selectedDataModelAgent?.PromptSKU) {
      navigate(
        `/databot?account=${accountState.name.toLowerCase()}&selected_agent=${
          selectedDataModelAgent.PromptSKU || ""
        }&selected_version=${version.Version.toString()}`
      );
    }
  };

  console.log("thread is ", thread);
  return (
    <div
      style={{
        height: "100%",
      }}
    >
      {!info_agent_webview && (
        <MainCard
          contentSX={{
            display: "flex",
            gap: 1,
          }}
          sx={{
            mb: "19px",
          }}
        >
          <DropdownMenu
            options={agentsByDataModel || []}
            selectedOption={selectedDataModelAgent}
            onSelectOption={handleAgentSelection}
            displayOption={(prompt) => prompt.AgentFriendlyName}
            buttonLabel="Agent Selection"
            isButtonDisabled={agentsByDataModelLoading}
          />

          <DropdownMenu
            options={selectedDataModelAgent?.Versions || []}
            selectedOption={selectedVersion}
            onSelectOption={handleVersionSelection}
            displayOption={(v) => `${v.Version} (${capitalize(v.Status)})`}
            buttonLabel="Version Selection"
            isButtonDisabled={agentsByDataModelLoading}
          />
        </MainCard>
      )}
      <Grid2 container spacing={2.5}>
        <Grid2
          size={info_agent_webview ? 12 : { xs: 12, sm: 12, md: 9, lg: 9 }}
        >
          <MainCard
            sx={
              {
                // height: "100%",
              }
            }
            contentSX={
              {
                // height: "calc(100vh - 60px)",
                // display: "flex",
                // flexDirection: "row",
              }
            }
          >
            <Grid container>
              <Grid item xs={12}>
                <Box
                  sx={{
                    height: info_agent_webview
                      ? "calc(100vh - 35px)"
                      : "calc(100vh - 190px)",
                    display: "flex",
                    flexDirection: "column",
                    flexGrow: 1,
                  }}
                >
                  <Paper
                    id="main-chatbot-paper"
                    sx={{
                      flexGrow: 1,
                      overflow: "auto",
                    }}
                  >
                    <Box
                      justifyContent={"space-between"}
                      display={"flex"}
                      alignItems={"center"}
                      position={"sticky"}
                      top={0}
                      bgcolor={"white"}
                      zIndex={10}
                      pb={2}
                    >
                      <Typography color={"primary"} fontWeight={"bold"}>
                        {/* <span className="orange-text">AdScore AI</span> */}
                        AI-Agent
                        {/* <span className="orange-text">AdScore AI</span> */}
                      </Typography>
                      {info_agent_webview && (
                        <Box display={"flex"} alignItems={"center"} gap={2}>
                          {dataModelDetail?.WebURL && (
                            <Button
                              variant="text"
                              onClick={() => {
                                handleClickLink(
                                  dataModelDetail.WebURL.toLowerCase(),
                                  "web_link"
                                );
                                window.open(
                                  `${ensureHttpsUrl(
                                    dataModelDetail.WebURL.toLowerCase()
                                  )}?source=${window.btoa(
                                    window.location.href
                                  )}`,
                                  "_self"
                                );
                              }}
                              sx={{
                                textTransform: "lowercase",
                              }}
                            >
                              {/* <Link sx={{ mr: 1 }} /> */}
                              {dataModelDetail.WebURL}
                            </Button>
                          )}

                          <DropdownMenuShare
                            handleClickLink={handleClickLink}
                          />
                        </Box>
                      )}
                    </Box>
                    <br />

                    {!thread[0] && !conversationLoading && (
                      <ChatbotCustomTemplate
                        onCustomPromptSubmit={onCustomPromptSubmit}
                      />
                    )}

                    {conversationLoading && (
                      <Box
                        sx={{
                          width: "100%",
                          height: "90%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          flexDirection: "column",
                          gap: 4,
                        }}
                      >
                        <CircularProgress disableShrink />
                        <Typography>Loading Conversation ...</Typography>
                      </Box>
                    )}
                    {thread.map((threadItem) =>
                      threadItem.orientation === "right" ? (
                        <MessageRight
                          message={threadItem.message}
                          key={threadItem.message}
                        />
                      ) : (
                        <MessageLeft
                          handleClickLink={handleClickLink}
                          feedback={threadItem.feedback}
                          message={threadItem.message}
                          key={threadItem.message}
                          sql={threadItem.sql}
                          like={threadItem.like}
                          request_id={threadItem.request_id}
                          onThumbsUp={() =>
                            handleThumbsUp(threadItem.request_id)
                          }
                          onThumbsDown={() =>
                            handleThumbsDown(threadItem.request_id)
                          }
                          onSubmitChatbotFeedback={handleQueryChatbotFeedback}
                          isLoadingFeedback={feedbackLoading}
                          chart={threadItem?.chart}
                          image={threadItem?.image}
                          totalResponseTime={threadItem?.response_total_time}
                          showFeedback={false}
                          showReaction={false}
                          showCode={false}
                          showAvatar={matches ? true : false}
                        />
                      )
                    )}

                    {isLoading && <MessageLeft isLoading={isLoading} />}
                    <div
                      style={{ float: "left", clear: "both" }}
                      ref={messageEndRef}
                    ></div>
                  </Paper>
                  <ChatbotTextInput
                    info_agent_webview={info_agent_webview}
                    isThreadMessageExist={thread.length > 0}
                    submitInput={onPromptSubmit}
                  />
                </Box>
              </Grid>
              {/* {!info_agent_webview && (
            <Grid item xs={2.5}>
              <Box
                sx={{
                  height: "calc(100vh - 90px)",
                  marginLeft: 2,
                  borderRadius: 2,
                  p: 1,
                  // width: "250px",
                  bgcolor: "primary.light",
                  overflow: "auto",
                }}
              >
                <ChatbotSidebar data={chatbotSessionsData} />
              </Box>
            </Grid>
          )} */}
            </Grid>
          </MainCard>
        </Grid2>

        {!info_agent_webview && matchesMd && (
          <Grid2 size={{ xs: 0, sm: 0, md: 3, lg: 3 }}>
            <MainCard
              sx={
                {
                  // height: "100%",
                }
              }
              contentSX={
                {
                  // height: "calc(100vh - 60px)",
                  // display: "flex",
                  // flexDirection: "row",
                }
              }
            >
              <Grid container>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      height: "calc(100vh - 190px)",
                      display: "flex",
                      flexDirection: "column",
                      flexGrow: 1,
                    }}
                  >
                    <Paper
                      id="main-chatbot-paper"
                      sx={{
                        flexGrow: 1,
                        overflow: "auto",
                      }}
                    >
                      <Box
                        className="responsive-ai-agent-paper"
                        justifyContent={"space-between"}
                        display={"flex"}
                        alignItems={"center"}
                      >
                        <Typography color={"primary"} fontWeight={"bold"}>
                          {/* <span className="orange-text">AdScore AI</span> */}
                          AI-Agent
                          {/* <span className="orange-text">AdScore AI</span> */}
                        </Typography>

                        <Box display={"flex"} alignItems={"center"} gap={2}>
                          {accountState.WebURL && (
                            <Button
                              variant="text"
                              sx={{
                                textTransform: "lowercase",
                              }}
                            >
                              {/* <Link sx={{ mr: 1 }} /> */}
                              {accountState.WebURL.toLowerCase()}
                            </Button>
                          )}

                          <DropdownMenuShare handleClickLink={() => {}} />
                        </Box>
                      </Box>
                      <br />

                      {!thread[0] && !conversationLoading && (
                        <ChatbotCustomTemplate
                          isResponsiveDemo={true}
                          onCustomPromptSubmit={onCustomPromptSubmit}
                        />
                      )}

                      {conversationLoading && (
                        <Box
                          sx={{
                            width: "100%",
                            height: "90%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                            gap: 4,
                          }}
                        >
                          <CircularProgress disableShrink />
                          <Typography>Loading Conversation ...</Typography>
                        </Box>
                      )}
                      {thread.map((threadItem) =>
                        threadItem.orientation === "right" ? (
                          <MessageRight
                            message={threadItem.message}
                            key={threadItem.message}
                          />
                        ) : (
                          <MessageLeft
                            handleClickLink={handleClickLink}
                            feedback={threadItem.feedback}
                            message={threadItem.message}
                            key={threadItem.message}
                            sql={threadItem.sql}
                            like={threadItem.like}
                            request_id={threadItem.request_id}
                            onThumbsUp={() =>
                              handleThumbsUp(threadItem.request_id)
                            }
                            onThumbsDown={() =>
                              handleThumbsDown(threadItem.request_id)
                            }
                            onSubmitChatbotFeedback={handleQueryChatbotFeedback}
                            isLoadingFeedback={feedbackLoading}
                            chart={threadItem?.chart}
                            image={threadItem?.image}
                            totalResponseTime={threadItem?.response_total_time}
                            showFeedback={false}
                            showReaction={false}
                            showCode={false}
                            showAvatar={false}
                          />
                        )
                      )}

                      {isLoading && <MessageLeft isLoading={isLoading} />}
                      <div
                        style={{ float: "left", clear: "both" }}
                        ref={messageEndRef}
                      ></div>
                    </Paper>
                    <ChatbotTextInput
                      info_agent_webview={info_agent_webview}
                      isThreadMessageExist={thread.length > 0}
                      submitInput={onPromptSubmit}
                    />
                  </Box>
                </Grid>
                {/* {!info_agent_webview && (
            <Grid item xs={2.5}>
              <Box
                sx={{
                  height: "calc(100vh - 90px)",
                  marginLeft: 2,
                  borderRadius: 2,
                  p: 1,
                  // width: "250px",
                  bgcolor: "primary.light",
                  overflow: "auto",
                }}
              >
                <ChatbotSidebar data={chatbotSessionsData} />
              </Box>
            </Grid>
          )} */}
              </Grid>
            </MainCard>
          </Grid2>
        )}
      </Grid2>
    </div>
  );
}
