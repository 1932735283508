export const randomId = () => Math.floor(Math.random() * 1000000000);

export function convertToTitleCase(str: string) {
  if (!str) {
    return "";
  }
  return str.toLowerCase().replace(/\b\w/g, (s) => s.toUpperCase());
}

export function ensureHttpsUrl(url: string): string {
  if (!url.startsWith("https://")) {
    return `https://${url}`;
  }
  return url;
}
