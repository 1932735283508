import React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { SxProps, Theme } from "@mui/material";

export interface DropdownMenuProps<T> {
  options: T[];
  selectedOption?: T | null;
  onSelectOption: (option: T) => void;
  displayOption: (option: T) => React.ReactNode;
  buttonLabel: string;
  isButtonDisabled?: boolean;
  buttonSx?: SxProps<Theme>;
}

export function DropdownMenu<T>({
  options,
  selectedOption,
  onSelectOption,
  displayOption,
  buttonLabel,
  isButtonDisabled = false,
  buttonSx = {},
}: DropdownMenuProps<T>) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOptionSelect = (option: T) => {
    onSelectOption(option);
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        disabled={isButtonDisabled}
        id="dropdown-button"
        aria-controls={open ? "dropdown-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        variant="outlined"
        sx={buttonSx}
      >
        {selectedOption ? displayOption(selectedOption) : buttonLabel}
      </Button>
      <Menu
        id="dropdown-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "dropdown-button",
        }}
      >
        {options.map((option, index) => (
          <MenuItem key={index} onClick={() => handleOptionSelect(option)}>
            {displayOption(option)}
          </MenuItem>
        ))}
        {!options?.[0] && (
          <MenuItem disableRipple key={-1}>
            No options
          </MenuItem>
        )}
      </Menu>
    </>
  );
}
