import { IGenericHookProps } from "@/shared/type";
import { MESSAGES } from "@/config/dialogMessages.config";
import useNotification from "@/hooks/useNotification";
import { useState } from "react";
import { handleInternalServerError } from "@/utils/errorHandler";
import { sentryCaptureException } from "@/services/sentry.service";
import { SENTRY_TAG_NAME } from "@/config/sentry.config";
import { AxiosError } from "axios";
import {
  chatbotSalesAgentApi,
  useQueryChatbotMutation,
} from "@/store/api/chatbot";
import { useAppSelector } from "@/store";
import { v4 as uuid } from "uuid";
import { IConversationEvents } from "@/models/conversation";

/**
 * Chatbot utility hook
 */
export default function useChatbot({
  onSuccess,
  onFailure,
  session_id,
  data_model,
  sku,
  version,
  instant_chat_fisrt_conversation,
  show_predefined_questask_responses,
  info_agent_webview = false,
  events,
  init_conversation,
}: IGenericHookProps & {
  session_id?: string | null;
  data_model?: string | null;
  sku?: string | null;
  version?: string | null;
  instant_chat_fisrt_conversation?: boolean;
  show_predefined_questask_responses?: boolean;
  info_agent_webview?: boolean;
  events?: IConversationEvents;
  init_conversation?: boolean;
}) {
  const [isLoading, setIsLoading] = useState(false);
  const { addNotification } = useNotification();
  const [queryCahtbotMutation] = useQueryChatbotMutation();

  const [queryChatbotSalesAgentMutation] =
    chatbotSalesAgentApi.useQueryChatbotMutation();
  const selectedAccount = useAppSelector(
    (state) => state.store.selectedAccount
  );

  const queryChatbot = async ({
    prompt,
    isIntro = false,
    preDefinedQuestaskPosition = null, 
  }: {
    prompt: string;
    isIntro?: boolean;
    preDefinedQuestaskPosition?: number | null;
  }) => {
    try {
      // Generating UUID to send to backend..
      const newUuid = uuid();
      const sessionUuid = session_id || uuid();

      setIsLoading(true);

      let response = null;

      if (info_agent_webview && data_model) {
        response = await queryChatbotSalesAgentMutation({
          prompt,
          request_id: newUuid,
          session_id: sessionUuid,
          store_id: data_model,
          sku: sku,
          version: version,
          instant_chat_fisrt_conversation,
          show_predefined_questask_responses,
          events: events,
          init_conversation,
          isIntro,
          isPublicSalesAgent: window.self !== window.top,
          preDefinedQuestaskPosition: preDefinedQuestaskPosition,
        });
      } else {
        response = await queryCahtbotMutation({
          prompt,
          request_id: newUuid,
          session_id: sessionUuid,
          sku: sku,
          version: version,
          store_id: selectedAccount,
          init_conversation,
          isIntro,
          preDefinedQuestaskPosition,
        });
      }

      if ("data" in response) {
        /**
         * res.data
         * The data should contain the object similar to mockMemberList..
         * Pending Invitation = if auth0Id is null then it will be
         * treated as pending invitation.
         */
        onSuccess?.();
        return { ...response.data, session_id: sessionUuid };
      } else {
        addNotification({
          title: "Oops!",
          message: MESSAGES.GENERAL_ERROR_MESSAGE,
          type: "error",
        });

        return false;
      }
    } catch (err) {
      console.log("error is ", err);
      const error = err as AxiosError;

      if (error?.response?.status === 500) {
        handleInternalServerError({ addNotification, error });
      } else {
        addNotification({
          title: "Oops!",
          message: MESSAGES.GENERAL_ERROR_MESSAGE,
          type: "error",
        });
      }

      onFailure?.();
      sentryCaptureException({
        tag: {
          name: SENTRY_TAG_NAME.API_NAME,
          value: "Chatbot Query",
        },
        error: err,
      });

      return false;
    } finally {
      setIsLoading(false);
    }
  };

  return {
    queryChatbot,
    isLoading,
  };
}
