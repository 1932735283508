export function sendEventToParentContainer({
  conversation_id,
}: {
  conversation_id: string;
}) {
  console.log('Initializing sending Event (conversation_id) to parent')
  if (!conversation_id) {
    console.log("Conversation id does not exist in sendEventToParentContainer");
    return;
  }
  if (!window.top) {
    console.log(
      "window.top layer does not exist in sendEventToParentContainer"
    );
    return;
  }

  window.top.postMessage({ conversation_id }, "*");
  console.log("Event (conversation_id) to parent sent successfuly..");
}
