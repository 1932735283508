import { useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { styled, useTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { drawerWidth } from "@/store/constant";
import { useAuth0 } from "@auth0/auth0-react";
import Sidebar from "@/components/organisms/Sidebar";

const Main = styled<any>("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }: any) => ({
    ...theme.typography.mainContent,
    marginBottom: 0,
    ...(!open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      [theme.breakpoints.up("md")]: {
        width: `calc(100% - ${drawerWidth}px)`,
      },
      [theme.breakpoints.down("md")]: {
        width: `calc(100% - ${drawerWidth}px)`,
        padding: "16px",
      },
      [theme.breakpoints.down("sm")]: {
        width: `calc(100% - ${drawerWidth}px)`,
        padding: "16px",
        marginRight: "0px",
      },
    }),
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
      width: `calc(100% - ${drawerWidth}px)`,
      [theme.breakpoints.down("md")]: {
        marginLeft: "20px",
      },
      [theme.breakpoints.down("sm")]: {
        marginLeft: "10px",
      },
    }),
  })
);

const MainTemplate = () => {
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down("lg"));
  const [openDrawer, setOpenDrawer] = useState(true);
  const { isAuthenticated, error } = useAuth0();

  const handleLeftDrawerToggle = () => {
    setOpenDrawer(!openDrawer);
  };

  useEffect(() => {
    setOpenDrawer(!matchDownMd);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchDownMd]);

  const isValidNode = () => {
    return isAuthenticated && !error;
  };

  return isValidNode() ? (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      {/* drawer */}
      <Sidebar
        drawerOpen={openDrawer}
        drawerToggle={handleLeftDrawerToggle}
      />
      {/* main content */}
      <Main open={openDrawer}>
        <Outlet />
      </Main>
    </Box>
  ) : (
    <Outlet />
  );
};

export default MainTemplate;
