import { useState } from "react";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import ImageViewer from "react-simple-image-viewer";
import './style.css';

const TypingMarkdown = ({
  message,
  handleClickLink,
}: {
  message: string;
  handleClickLink?: (link: string, media_type: string) => void;
}) => {
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState<string | null>(null);

  const openImageViewer = (src: string) => {
    setCurrentImage(src);
    setIsViewerOpen(true);
  };

  const closeImageViewer = () => {
    setCurrentImage(null);
    setIsViewerOpen(false);
  };

  return (
    <>
      <Markdown
        remarkPlugins={[remarkGfm]}
        components={{
          code: ({children, ...props}) => (
            <pre>
              <code {...props} className="markdown-code-block">{children}</code>
            </pre>
          ),
          ul: ({ ...props }) => (
            <ul style={{ margin: "1em 0", paddingLeft: "1.5em" }} {...props} />
          ),
          ol: ({ ...props }) => (
            <ol style={{ margin: "1em 0", paddingLeft: "1.5em" }} {...props} />
          ),
          h1: ({ ...props }) => (
            <h1
              style={{ marginTop: "1em", marginBottom: "0.5em" }}
              {...props}
            />
          ),
          h2: ({ ...props }) => (
            <h2
              style={{ marginTop: "1em", marginBottom: "0.5em" }}
              {...props}
            />
          ),
          h3: ({ ...props }) => (
            <h3
              style={{ marginTop: "1em", marginBottom: "0.5em" }}
              {...props}
            />
          ),
          h4: ({ ...props }) => (
            <h4
              style={{ marginTop: "1em", marginBottom: "0.5em" }}
              {...props}
            />
          ),
          h5: ({ ...props }) => (
            <h5
              style={{ marginTop: "1em", marginBottom: "0.5em" }}
              {...props}
            />
          ),
          h6: ({ ...props }) => (
            <h6
              style={{ marginTop: "1em", marginBottom: "0.5em" }}
              {...props}
            />
          ),
          a: ({ ...props }) => {
            const isEmail = props.href && props.href.startsWith("mailto:");
            return isEmail ? (
              <a
                target="_blank"
                rel="noopener noreferrer"
                {...props}
                onClick={() =>
                  handleClickLink &&
                  props.href &&
                  handleClickLink(props.href, "email_link")
                }
              />
            ) : (
              <span>
                <strong>{props.children}</strong>
                &nbsp;
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  {...props}
                  onClick={() =>
                    handleClickLink &&
                    props.href &&
                    handleClickLink(props.href, "link")
                  }
                >
                  <span>({props.href})</span>
                </a>
              </span>
            );
          },
          img: ({ ...props }) => (
            <img
              {...props}
              style={{
                cursor: "pointer",
                textAlign: "center",
                margin: "auto",
                width: "100%",
                maxHeight: "340px",
                objectFit: "contain",
              }}
              onClick={() => openImageViewer(props.src || "")}
            />
          ),
        }}
      >
        {message}
      </Markdown>
      {isViewerOpen && currentImage && (
        <ImageViewer
          src={[currentImage]}
          currentIndex={0}
          onClose={closeImageViewer}
          backgroundStyle={{
            backgroundColor: "rgba(0,0,0,0.9)",
            zIndex: 1200,
          }}
        />
      )}
    </>
  );
};

export default TypingMarkdown;
