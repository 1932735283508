export const calculateCTR = (clicks: number, impressions: number): number => {
  if (impressions === 0) {
    return 0;
  }
  return (clicks / impressions) * 100;
};

export const calculateCPC = (cost: number, clicks: number): number => {
  if (clicks === 0) {
    return 0;
  }
  return cost / clicks;
};

export const calculateCPM = (cost: number, impressions: number): number => {
  if (impressions === 0) {
    return 0;
  }
  return (cost / impressions) * 1000;
};

export const calculateCTC = (
  conversation_started: number,
  clicks: number
): number => {
  if (clicks === 0) {
    return 0;
  }
  return conversation_started / clicks;
};

export const calculateCPA = (
  cost: number,
  conversation_started: number
): number => {
  if (conversation_started === 0) {
    return 0;
  }
  return cost / conversation_started;
};

export const calculateCTI = (
  conversation_started_with_intent: number,
  clicks: number
): number => {
  if (clicks === 0) {
    return 0;
  }
  return conversation_started_with_intent / clicks;
};

export const calculateCPI = (
  cost: number,
  conversation_started_with_intent: number
): number => {
  if (conversation_started_with_intent === 0) {
    return 0;
  }
  return cost / conversation_started_with_intent;
};

export const calculateRelativeIntentStrength = (
  intentStrength: number,
  conversationsWithIntentStarted: number
): number => {
  if (conversationsWithIntentStarted === 0) {
    return 0;
  }
  return intentStrength / conversationsWithIntentStarted;
};

export const calculateConversationWithIntentPercentage = (
  conversationsWithIntentStarted: number,
  conversationsStarted: number
): number => {
  if (conversationsStarted === 0) {
    return 0;
  }
  return conversationsWithIntentStarted / conversationsStarted;
};

export const calculateAverageInfoLinkedClick = (
  infoLinksClicked: number,
  conversationsStarted: number
): number => {
  if (conversationsStarted === 0) {
    return 0;
  }
  return infoLinksClicked / conversationsStarted;
};

export const calculateAverageContactLinkedClick = (
  contactLinksClicked: number,
  conversationsStarted: number
): number => {
  if (conversationsStarted === 0) {
    return 0;
  }
  return contactLinksClicked / conversationsStarted;
};
