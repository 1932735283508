import { Box, Button, Card, Typography } from "@mui/material";
import { useNavigate } from "react-router";
import "./style.css";
import SuccessCheck from "@/components/atoms/SuccessCheck";
import ErrorCross from "@/components/atoms/ErrorCross";
import AlertAnimated from "@/components/atoms/AlertAnimated";
import Logo from "@/components/atoms/Logo";

interface PageAlertTemplateProps {
  isSuccess?: boolean;
  isError?: boolean;
  isAlert?: boolean;
  isLoading?: boolean;
  showGoBackButton?: boolean;
  showLogo?: boolean;
  message?: string;
  title?: string;
  goBackText?: string;
  onGoBack?: () => void;
}

export default function PageAlert({
  isSuccess = false,
  isError = false,
  isAlert = false,
  isLoading,
  showGoBackButton = false,
  showLogo = true,
  goBackText = "Back to Dashboard",
  onGoBack,
  message,
  title,
}: PageAlertTemplateProps) {
  const navigate = useNavigate();

  const handleGoBack = () => {
    if (onGoBack) {
      onGoBack();
      return;
    }
    navigate("/");
    return;
  };

  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: 2,
        bgcolor: "primary.light",
        backgroundColor: "#FFDEE9",
        backgroundImage: "linear-gradient(90deg, #fde9f0 0%, #c3fcfa 100%)",
      }}
    >
      {showLogo && <Logo />}
      <Card
        sx={{
          p: 4,
          width: "400px",
          textAlign: "center",
          borderRadius: 3,
        }}
      >
        <Typography variant="h3">{title}</Typography>

        {isSuccess && <SuccessCheck />}

        {isError && <ErrorCross />}

        {isAlert && <AlertAnimated />}

        {isLoading && <div className="invitation-loader"></div>}

        <Typography variant="h5" sx={{ mt: 2 }}>
          {message}
        </Typography>
        {showGoBackButton && (
          <Button sx={{ mt: 2 }} onClick={handleGoBack}>
            {goBackText}
          </Button>
        )}

        {/* <MailOutline
          sx={{
            fontSize: 160,
            color: "primary.main",
          }}
        />
        <Typography variant="body1">
          You have been <span className="orange-text text-bold">INVITED</span>{" "}
          to join the <span className="text-bold">ABC store</span>.
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 2,
            mt: 4,
          }}
        >
          <Button
            sx={{ flex: 1 }}
            variant="outlined"
            color="primary"
            onClick={onRejectInvitation}
          >
            Reject
          </Button>
          <Button
            sx={{
              flex: 1,
              boxShadow: "none",
              "&:hover": {
                boxShadow: "none",
              },
            }}
            variant="contained"
            color="primary"
            onClick={onAcceptInvitation}
          >
            Accept
          </Button>
        </Box> */}
      </Card>
    </Box>
  );
}
