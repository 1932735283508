import { useFetchAgentsByDataModelQuery } from "@/store/api/chatbot";
import useFetchAccountState from "../store-management/useFetchAccountState";
import { useEffect, useState } from "react";
import { DataModelAgentWithVersion } from "@/models/agent";

const useFetchDataModelAgents = ({
  info_agent_webview = false,
}: {
  info_agent_webview?: boolean;
}) => {
  const [dataModelAgents, setDataModelAgents] = useState<
    DataModelAgentWithVersion[]
  >([]);
  const { accountState } = useFetchAccountState({
    fetchOnlyStore: true,
  });
  const { data, isLoading } = useFetchAgentsByDataModelQuery(
    { data_model: accountState.id },
    { skip: !accountState.id || info_agent_webview }
  );
  useEffect(() => {
    if (data?.data) {
      const groupedData = data.data.reduce((acc, item) => {
        const key = `${item.PromptSKU}`;
        if (!acc[key]) {
          acc[key] = {
            PromptSKU: item.PromptSKU,
            AgentFriendlyName: item.AgentFriendlyName,
            Versions: [],
            IsDefault: item.IsDefault,
          };
        }
        acc[key].Versions.push({ Version: item.Version, Status: item.Status });
        return acc;
      }, {} as Record<string, DataModelAgentWithVersion>);

      setDataModelAgents(Object.values(groupedData));
    }
  }, [data?.data, data?.data.length]);

  return {
    data: dataModelAgents,
    isLoading,
  };
};
export default useFetchDataModelAgents;
