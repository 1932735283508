import React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { TextField } from "@mui/material";

export interface DropdownMenuProps<T> {
  options: T[];
  selectedOption?: T | null;
  onSelectOption: (option: T) => void;
  displayOption: (option: T) => React.ReactNode;
  buttonLabel: string;
  isButtonDisabled?: boolean;
}

export function DropdownMenuShare({
  handleClickLink,
}: {
  handleClickLink?: (link: string, media_type: string) => void;
}) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        id="dropdown-button"
        aria-controls={open ? "dropdown-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        variant="outlined"
      >
        Save
        {/* <ShareOutlined sx={{ ml: 1 }} fontSize="small" /> */}
      </Button>
      <Menu
        id="dropdown-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          disableRipple
          disableTouchRipple
          sx={{ "&:hover": { background: "transparent" } }}
        >
          <TextField
            value={window.location.href}
            disabled
            sx={{ width: 300 }}
          />
          <Button
            onClick={() => {
              navigator.clipboard.writeText(window.location.href);
              handleClickLink &&
                handleClickLink(window.location.href, "share_link");
              handleClose();
            }}
            variant="text"
            sx={{ "&:hover": { background: "transparent" } }}
          >
            Copy Link
          </Button>
        </MenuItem>
      </Menu>
    </>
  );
}
